body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.login-form {
}
.login-form-forgot {
  float: right;
  /* color:rgb(8, 90, 243) */
}
.login-form-button {
  width: 100%;
}

.ant-form-item-control-input-content button{
  width: 100%;

}

a {
  /* color: rgb(0, 119, 255); */
}
.ant-btn {

}

.ant-collapse-header {
  display: flex!important;
  align-items:center!important;
}

.react-multiple-carousel__arrow {
  background: rgba(80, 80, 80, 0.8)!important;
}

.react-multiple-carousel__arrow :hover{
  background: rgba(138, 115, 115, 0.8)!important;
  transition-duration: 1s!important;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-select-single .ant-select-selector {
  border-radius: 50px;
}

:where(.css-12jzuas).ant-select-single .ant-select-selector {
  border-radius: 50px;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  /* color: #49a16b; */
  /* text-shadow: 0 0 0.25px currentcolor; */
}
/* Event CSS */
.my-app {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-height: 80px;
  box-sizing: border-box;
  padding: 30px;
}

@keyframes k-loading-animation  {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.k-i-loading.k-example-loading {
  font-size: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(144,152,165);
}

.k-i-loading.k-example-loading::before,
.k-i-loading.k-example-loading::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  content: "";
  box-sizing: inherit;
  border-radius: 50%;
  border-width: .05em;
  border-style: solid;
  border-color: currentColor;
  border-top-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
}
.k-icon.k-i-loading.k-example-loading::before,
.k-icon.k-i-loading::after {
  content: "";
}

.k-i-loading.k-example-loading::before {
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  animation: k-loading-animation .7s linear infinite;
}

.k-i-loading.k-example-loading::after {
  margin-top: -0.25em;
  margin-left: -0.25em;
  width: .5em;
  height: .5em;
  animation: k-loading-animation reverse 1.4s linear infinite;
}

.example-wrapper {
  min-height: 280px;
  align-content: flex-start;
}
.example-wrapper p, .example-col p {
  margin: 20px 0 10px;
}
.example-wrapper p:first-child, .example-col p:first-child {
  margin-top: 0;
}
.example-col {
  display: inline-block;
  vertical-align: top;
  padding-right: 20px;
  padding-bottom: 20px;
}
.example-config {
  margin: 0 0 20px;
  padding: 20px;
  background-color: rgba(0,0,0,.03);
  border: 1px solid rgba(0,0,0,.08);
}
.event-log {
  margin: 0;
  padding: 0;
  max-height: 100px;
  overflow-y: auto;
  list-style-type: none;
  border: 1px solid rgba(0,0,0,.08);
  background-color: white;
}
.event-log li {
  margin: 0;
  padding: .3em;
  line-height: 1.2em;
  border-bottom: 1px solid rgba(0,0,0,.08);
}
.event-log li:last-child {
  margin-bottom: -1px;
}
/* customize - scheduler*/
.k-button-solid-base.k-selected {
  border-color: #1acf3e!important;
  color: white!important;
  background-color: #1acf3e!important;
}
.k-animation-container-shown{
  z-index: 100000!important;
}

.k-link.k-menu-link.k-selected{
  background-color: #1acf3e!important;
}